import env from "react-dotenv";

const baseUrl = env.BACKEND_URL

const error = async (message, result) => {
    const statusCode = result.status
    const body = JSON.stringify(await result.json())
    alert(`${message}: ${statusCode} ${body}`)
    throw await error(message)
}

export const createOrder = async (checkout) => {
    const url = `${baseUrl}/order/create`
    const requestBody = {
        accounting_unit: checkout.accounting_unit,
        reference: checkout.reference,
        amount: checkout.amount,
        operation: checkout.operation
    }
    const result = await fetch(url, {
        method: "POST",
        body: JSON.stringify(requestBody)
    })
    if (result.status !== 200) {
        await error(`createOrder failed`, result)
    }
    return await result.json()
}

export const getPaymentMethods = async (orderId) => {
    const url = `${baseUrl}/order/${orderId}/get-available-payment-methods`
    const result = await fetch(url)
    if (result.status !== 200) {
        await error(`getPaymentMethods failed`, result)
    }

    return await result.json()
}

export const getOrder = async (orderId) => {
    const url = `${baseUrl}/order/${orderId}`
    const result = await fetch(url)
    if (result.status !== 200) {
        await error(`getOrder failed`, result)
    }
    return await result.json()
}

export const setPaymentMethodInCheckout = async (orderId, paymentMethod, locale) => {
    const url = `${baseUrl}/order/${orderId}/set-payment-method`
    const requestBody = {
        payment_method: paymentMethod,
        locale
    }
    const result = await fetch(url, {
        method: "POST",
        body: JSON.stringify(requestBody)
    })
    if (result.status !== 200) {
        await error(`initCheckout failed`, result)
    }
    const resultJson = await result.json()
    console.log("initCheckout result", resultJson)
    return resultJson.checkoutResponse
}

export const submitPaymentDetails = async (orderId, details) => {
    const url = `${baseUrl}/order/${orderId}/payment-details`
    const requestBody = {
        checkoutDetails: details
    }
    // naming convention for the backend
    const transformedRequestBody = {
        ...requestBody,
        checkoutDetails: {
            ...requestBody.checkoutDetails,
            shopper_info: requestBody.checkoutDetails.shopperInfo
        }
    };
    delete transformedRequestBody.checkoutDetails.shopperInfo;

    const result = await fetch(url, {
        method: "POST",
        body: JSON.stringify(transformedRequestBody)
    })
    if (result.status !== 200) {
        await error(`checkoutDetails failed`, result)
    }

    const r = await result.json()
    console.log(r)

    return r.checkoutResponse
}

export const refundOrder = async (orderId, refundAmount) => {
    const url = `${baseUrl}/order/${orderId}/refund`
    const requestBody = {
        refund_amount: refundAmount
    }
    const result = await fetch(url, {
        method: "POST",
        body: JSON.stringify(requestBody)
    })
    if (result.status !== 200) {
        await error(`refundOrder failed`, result)
    }
    const r = await result.json()
    return r
}

export const captureOrder = async (orderId, captureAmount) => {
    const url = `${baseUrl}/order/${orderId}/capture`
    const requestBody = {
        capture_amount: captureAmount
    }
    const result = await fetch(url, {
        method: "POST",
        body: JSON.stringify(requestBody)
    })
    if (result.status !== 200) {
        await error(`captureOrder failed`, result)
    }
    const r = await result.json()
    return r
}

export const cancelOrder = async (orderId) => {
    const url = `${baseUrl}/order/${orderId}/cancel`
    const result = await fetch(url, {
        method: "POST",
        body: ""
    })
    if (result.status !== 200) {
        await error(`cancelOrder failed`, result)
    }
    const r = await result.json()
    return r
}